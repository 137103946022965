"use client";

import { Button, Heading, Modal, Text, TextButton } from "ui-components";

import { useOrganizationDeleteWpPost } from "@/src/hooks/api/generated";

import { makeToast } from "../Toast/Toast";

export interface IDeleteNewsModalProps {
  newsItemId: string;
  isOpen?: boolean;
  onOpenChange: (isOpen: boolean) => void;
  refetch?: () => void;
}

export function DeleteNewsModal({
  newsItemId,
  isOpen,
  onOpenChange,
  refetch,
}: IDeleteNewsModalProps): JSX.Element {
  const { mutate: deleteNews, status } = useOrganizationDeleteWpPost();

  const handleDelete = (): void => {
    deleteNews(
      { postType: "news", postId: newsItemId },
      {
        onSuccess: () => {
          makeToast({
            intent: "success",
            title: "Article deleted successfully.",
          });
          refetch?.();
          onOpenChange(false);
        },
        onError: () => {
          makeToast({
            intent: "danger",
            title: "Something went wrong",
          });
          onOpenChange(false);
        },
      }
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={(value) => onOpenChange(value)}
      closeIcons
      isDismissable
      animatedModalClassName="md:min-w-[500px] flex flex-1 items-center justify-center"
    >
      <div className="flex flex-col items-center justify-start gap-10 pt-5">
        <Heading align="center">Delete article</Heading>
        <Text align="center">
          Are you sure you would like to delete this article?
        </Text>
        <div className="flex flex-col items-center justify-start gap-4">
          <Button
            onClick={handleDelete}
            isLoading={status === "pending"}
            isDisabled={status === "pending"}
          >
            <Text>Delete Article</Text>
          </Button>
          <TextButton underLine onPress={() => onOpenChange(false)}>
            Cancel
          </TextButton>
        </div>
      </div>
    </Modal>
  );
}
