import { VariantProps, cva } from "class-variance-authority";
import { useMemo } from "react";
import { Heading, Link } from "ui-components";

import { WpPostEntity } from "@/src/hooks/api/generated";

import { Slider } from "../slider/Slider";

import { NewsCard } from "./NewsCard";

interface IMyGworkNewsWPProps extends VariantProps<typeof sectionClasses> {
  title: string;
  news: WpPostEntity[];
  slidesToShow?: number;
}

export function MyGworkNewsWP({
  title,
  news,
  slidesToShow = 4,
  spacing,
}: IMyGworkNewsWPProps): JSX.Element {
  const settings = {
    dots: false,
    infinite: news.length > 1,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow > 1 ? 2 : slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderNewsItems: JSX.Element[] = useMemo(
    () =>
      news.map((newsItem) => (
        <NewsCard key={newsItem.ID} newsItem={newsItem} />
      )),
    [news]
  );

  return (
    <div className={sectionClasses({ spacing })}>
      <div className="flex flex-col gap-y-2.5 sm:flex-row justify-between w-full items-center px-7 lg:px-0">
        <Heading variant="h2">{title}</Heading>
        <Link href="/news">View All News</Link>
      </div>
      <Slider settings={settings}>{renderNewsItems}</Slider>
    </div>
  );
}

const sectionClasses = cva("flex flex-col w-full", {
  variants: {
    spacing: {
      large: "gap-y-5 lg:gap-y-10",
      small: "gap-y-5",
    },
  },

  defaultVariants: {
    spacing: "large",
  },
});
