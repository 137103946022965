import dayjs from "dayjs";
import { VerticalDotsMenuIcon } from "icons";
import React, { useState } from "react";
import { Button as RAButton } from "react-aria-components";
import { Link, Text, ImageWrapper, Popover } from "ui-components";

import { IWPTerm } from "@/src/@types/Wordpress";
import { PLACEHOLDER_IMAGE } from "@/src/constants/images";
import { WpPostEntity } from "@/src/hooks/api/generated";
import { useNavigate } from "@/src/hooks/useNavigate";
import { transformCloudinaryUrlForCropping } from "@/src/utils/cloudinary";
import { concatString } from "@/src/utils/string";
import {
  extractTermRelationshipByType,
  getMetaValue,
  getTermMetaValue,
} from "@/src/utils/wordpress";
import { parseContent } from "@/utils/parseContent";

import { DeleteNewsModal } from "./DeleteNewsModal";

export function NewsCard({
  newsItem,
  isEditable = false,
  refetch,
}: Readonly<{
  newsItem: WpPostEntity;
  isEditable?: boolean;
  refetch?: () => void;
}>): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { push } = useNavigate();
  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] =
    useState(false);

  return (
    <>
      <DeleteNewsModal
        newsItemId={newsItem.ID}
        isOpen={isOpenDeleteConfirmationModal}
        onOpenChange={setIsOpenDeleteConfirmationModal}
        refetch={refetch}
      />
      <div className="flex flex-col p-2.5 gap-y-2.5" key={newsItem.ID}>
        <Link
          decoration={false}
          hasHoverEffect={false}
          href={`/news/${newsItem.post_name}`}
        >
          <ImageWrapper
            src={
              transformCloudinaryUrlForCropping(
                getMetaValue(newsItem, "_cloudinary")
              ) || PLACEHOLDER_IMAGE
            }
            alt={parseContent(newsItem.post_title)}
            width={325}
            height={171}
            className="rounded-t-xl mb-2.5 w-full h-[171px] object-cover"
            unoptimized
          />
        </Link>
        <div className="flex justify-between items-center">
          <div className="flex flex-wrap flex-col sm:flex-row gap-2.5 justify-center sm:justify-start">
            <div className="flex gap-2.5 justify-center sm:justify-start">
              {extractTermRelationshipByType(newsItem, "category").map(
                (term: IWPTerm) => (
                  <Text key={term.term_id} className="text-slate-600 text-lg">
                    {term.name}
                  </Text>
                )
              )}
            </div>
            <div className="w-full">
              <Text variant="small" className="block">
                {dayjs(newsItem.post_date).format("MMMM D, YYYY")}
              </Text>
            </div>
          </div>
          {isEditable && (
            <Popover onOpenChange={setIsPopoverOpen} isOpen={isPopoverOpen}>
              <Popover.Trigger>
                <VerticalDotsMenuIcon className="w-1 mr-2 cursor-pointer" />
              </Popover.Trigger>
              <Popover.Content
                placement="bottom right"
                className="p-4 rounded-3xl"
              >
                <div className="w-full flex flex-col items-center justify-center gap-y-2.5 ">
                  <RAButton
                    className="outline-none group"
                    onPress={() => {
                      push(`/edit-article/${newsItem.ID}`);
                    }}
                  >
                    <Text className="group-hover:text-primary">Edit</Text>
                  </RAButton>
                  <RAButton
                    className="outline-none group"
                    onPress={() => {
                      setIsOpenDeleteConfirmationModal(true);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <Text className="group-hover:text-danger">Delete</Text>
                  </RAButton>
                </div>
              </Popover.Content>
            </Popover>
          )}
        </div>
        <Link
          decoration={false}
          hasHoverEffect={false}
          href={`/news/${newsItem.post_name}`}
        >
          <p>{concatString(parseContent(newsItem.post_title), 100)}</p>
        </Link>
        <div className="flex flex-wrap gap-2.5 justify-center sm:justify-start">
          {extractTermRelationshipByType(newsItem, "post_tag").map(
            (term: IWPTerm, index: number) => {
              const tagColor = getTermMetaValue(term, "tag_color");
              const className = `border border-1 rounded-3xl px-2.5 py-1 flex items-center justify-center whitespace-nowrap no-underline ${
                tagColor ? "text-white" : "border-black hover:text-black"
              }`;

              return (
                <Link
                  key={term.term_id || index}
                  href={`/news?tag=${encodeURIComponent(term.name)}&page=1`}
                  hasHoverEffect={false}
                  decoration={false}
                  fontSize="base"
                  className={className}
                  style={
                    tagColor
                      ? { backgroundColor: tagColor, borderColor: tagColor }
                      : undefined
                  }
                >
                  {term.name}
                </Link>
              );
            }
          )}
        </div>
      </div>
    </>
  );
}
