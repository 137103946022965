import { ArrowLeftIcon, ArrowRightIcon } from "icons";
import React from "react";
import SlickSlider, { Settings } from "react-slick";

interface ISliderProps {
  settings: Settings;
  children: React.ReactNode;
}

const SlickButtonFix = (props: {
  children: JSX.Element;
  slideCount?: number;
  currentSlide?: number;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, currentSlide, slideCount, ...others } = props;
  return <div {...others}>{children}</div>;
};
export function Slider({ settings, children }: ISliderProps): JSX.Element {
  return (
    <SlickSlider
      {...settings}
      className="mx-5 lg:-mx-2.5"
      prevArrow={
        <SlickButtonFix>
          <ArrowLeftIcon className="h-5 w-5" />
        </SlickButtonFix>
      }
      nextArrow={
        <SlickButtonFix>
          <ArrowRightIcon className="h-5 w-5" />
        </SlickButtonFix>
      }
    >
      {children}
    </SlickSlider>
  );
}
