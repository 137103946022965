import { getCookie } from "cookies-next";
import LZString from "lz-string";
import { io, Socket } from "socket.io-client";

import { CookieKeys } from "./enums/CookieKeys.enum";

// Singleton pattern to ensure only one instance of the socket is used throughout the app
let socketInstance: Socket | null = null;

export const getSocket = (): Socket | null => {
  if (socketInstance) {
    return socketInstance;
  }

  let userData;
  const userDataString = LZString.decompressFromBase64(
    getCookie(CookieKeys.UserData) || ""
  );
  try {
    userData = JSON.parse(userDataString || "");
    if (!process.env.API_URL) {
      throw new Error("API URL is not defined");
    }
    socketInstance = io(process.env.API_URL, {
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      query: {
        userId: userData?.id,
      },
    });

    return socketInstance;
  } catch (error) {
    userData = {};
    return null;
  }

  return null;
};
